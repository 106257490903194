const ourProjects = [
    {
        id: 1,
        imgSrc: "land",
        altText: "land-img",
        linkPath: "/lands",
        headingText: "LANDS",
        pText: "Banking/Flipping...",
    },
    {
        id: 2,
        imgSrc: "houses",
        altText: "house-img",
        linkPath: "/houses",
        headingText: "HOUSES",
        pText: "Buy/Sell/Rent...",
    },
    // {
    //     id: 3,
    //     imgSrc: "modern-house",
    //     altText: "flexVilla-img",
    //     linkPath: "/build-vest",
    //     headingText: "BUILD VEST",
    //     pText: "Invest With Us...",
    // },
];

export default ourProjects;
